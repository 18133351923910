import React from "react";
import Contact from "../contact";
// import Question from "../question";
import arrowIcon from "../../images/home/arrow-icon.svg";
import bannerImage from "../../images/aboutUs/about.png";
import ourVission from "../../images/aboutUs/OurVision.jpg";
import ourMission from "../../images/aboutUs/OurMission.jpg";
import starIcon from "../../images/aboutUs/ri_star-line.svg";
import rightIcon from "../../images/aboutUs/right-icon.svg";
import iconright from "../../images/individual/PHONESVG.svg";
import iconleft from "../../images/individual/EMAILSVG.svg";
import aboutImageright from "../../images/aboutUs/Bhoomika_new.jpg";
import leftcontent from "../../images/aboutUs/ravin.jpg";
import isthiaDsc from "../../images/aboutUs/ishita.jpg";
import coreTopLeft from "../../images/individual/coreTopleft.svg";
import corebootomLeft from "../../images/individual/Group.png";
import middleimage from "../../images/individual/CoreValue.png";
import collobration from "../../images/individual/connected-persons-triangle-svgrepo-com 1.png";
import excellent from "../../images/individual/medal-svgrepo-com 1.png";
import blubmiddle from "../../images/individual/bulb-on-svgrepo-com 1.png";
import iconlinkedin from "../../images/footer/linkdins.png";
const AboutUs = () => {
  return (
    <div>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full ">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[24px] md:text-[54px]">
              About Us
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font  text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-sm flex items-center md:text-lg font-medium uppercase">
                About Us
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between max-xl:flex-col  items-center gap-12 max-w-[1440px] px-4 md:px-12 mx-auto pt-14  mb-10">
        <div className="flex xl:w-[40%] max-xl:w-full justify-center">
          <img src={bannerImage} alt="banner-image" className="max-xl:w-full" />
        </div>
        <div className="xl:w-[60%] md:px-4 flex flex-col justify-center">
          <div className="flex gap-2">
            <div className="flex items-center">
              <img src={starIcon} alt="star-icon" />
            </div>
            <div className="text-[#004b6b] hellix-font text-lg  font-semibold">
              About Us
            </div>
          </div>
          <div className="text-[#004b6b] hellix-font  text-[46px] max-md:text-[40px] font-semibold">
            Simplifying Migration One Step At A Time...
          </div>
          <div className="text-[#545b5f] hellix-font  text-lg font-normal">
            At Immiget Global, we believe that everyone deserves a chance to
            start anew. Our mission is to empower individuals and families to
            achieve their dreams of a better life abroad, free from the
            complexities of migration processes. As a leading migration
            consultancy with footprints in Australia, India & Philippines, we
            bridge the gap between your aspirations and reality. Our team of
            registered migration agents provide transparency, and clarity &
            gates complex visa processes, ensuring seamless transitions to
            settlement. Our mission remains to establish relationships and
            channels that deliver results and empower trust.  
          </div>
          <div class="md:shadow-custom p-4 md:p-8 mt-4 ">
            <div className="flex justify-center gap-10 max-sm:gap-2 max-sm:flex-col">
              <div className="flex gap-2">
                <div>
                  <img src={rightIcon} alt="" />
                </div>
                <div className="text-[#004b6b] hellix-font  text-lg font-medium">
                  Residency Consultation
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <img src={rightIcon} alt="" />
                </div>
                <div className="text-[#004b6b] hellix-font  text-lg font-medium">
                  Work Permit Processing
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-10 max-sm:gap-2 max-sm:flex-col">
              <div className="flex gap-2">
                <div>
                  <img src={rightIcon} alt="" />
                </div>
                <div className="text-[#004b6b] hellix-font  text-lg font-medium">
                  Family Sponsorship Aid
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <img src={rightIcon} alt="" />
                </div>
                <div className="text-[#004b6b] hellix-font  text-lg font-medium">
                  Residency Permit Services
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1440px] px-4 lg:px-12 mx-auto lg:py-8 py-3">
        <div className="text-[#284760]  hellix-font text-[32px] lg:text-[48px] font-semibold text-center">
          Meet Our Team
        </div>
        <div className="text-blue text-center hellix-font text-lg lg:text-2xl">
          Meet our dedicated team of immigration experts committed to providing
          personalized guidance and support throughout your journey to
          Australia.
        </div>
      </div>
      <div className="max-w-[1440px] px-4 lg:px-12 mx-auto lg:py-8 py-2">
        <div className="lg:flex gap-8 justify-between">
          <div className="lg:w-[40%] bg-blue rounded-[15px]">
            <img
              src={leftcontent}
              alt="left-content"
              className="ml-4 mt-4 md:h-[520px] rounded-[15px]"
            />
          </div>
          <div className="lg:w-[60%] flex flex-col gap-2 ml-4">
            <div className="text-[#284760] max-md:mt-2 hellix-font text-[32px] lg:text-[48px] font-semibold">
              Ravin Parekh
            </div>
            <div className="text-[#284760] text-xl font-semibold">
              Founder & CEO
            </div>
            <div className="lg:text-[22px] text-lg text-[#1e1e1e] hellix-font font-normal">
              Ravin Parekh, visionary founder and director, driving innovation
              and growth at Immiget Global. Ravin passionately empowers others
              to achieve their dreams by leveraging his expertise and personal
              experience as an international student. With razor-sharp business
              acumen and nuanced understanding of the Australian market. Ravin's
              leadership philosophy is embracing individuality. By empowering
              and demonstrating a culture of authenticity, he redefines team
              potential now and then, delivering exceptional results for
              clients and partners.
            </div>
            <div className="flex gap-6 mt-4">
              {/* Email Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "mailto:ravin@immigetglobal.com";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconleft}
                      alt="icon-left"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* Phone Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "tel:+610420340811";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconright}
                      alt="icon-right"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* LinkedIn Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "https://www.linkedin.com/in/ravin-parekh-03316774/";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconlinkedin}
                      alt="linkedin-icon"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="flex gap-3 sm:justify-center md:justify-start max-md:mt-2 mt-4">
              <div>
                <Link to="https://www.facebook.com/">
                  {" "}
                  <img src={facbook} alt="facebook-icon" />
                </Link>
              </div>
              <div>
                <Link to="https://x.com/?lang=en&mx=2">
                  {" "}
                  <img src={twitter} alt="twitter-icon" />
                </Link>
              </div>
              <div>
                <Link to="https://www.linkedin.com/home/?originalSubdomain=in">
                  {" "}
                  <img src={linkdin} alt="linkdin-iicon" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="max-w-[1440px] px-4 lg:px-12 mx-auto lg:py-8 py-2">
        <div className="  lg:hidden  mt-4 bg-blue rounded-[15px] pr-2">
          <img
            src={aboutImageright}
            alt="about-right"
            className="mb-2 md:h-[500px]"
          />
        </div>
        <div className="lg:flex gap-8 justify-between">
          <div className="lg:w-[60%] flex flex-col gap-2 ml-4">
            <div className="text-[#284760] hellix-font text-[32px] lg:text-[48px] font-semibold">
              Bhoomika Godara
            </div>
            <div className="text-[#284760] text-xl font-semibold">
              Principal Migration Agent (MARN 2418453)
            </div>
            <div className="lg:text-[22px] text-lg text-[#1e1e1e] hellix-font font-normal">
              Bhoomika’s passion for providing exceptional migration services
              stems from her personal experiences as an international student.
              With more than 8 years of experience in the migration field, she
              has been instrumental in helping many individuals and businesses
              achieve success by navigating their migration journeys. Starting
              her career as a migration administrator, Bhoomika’s commitment to
              supporting others ultimately led her to earn her MARA migration
              license. Her diverse portfolio includes assisting students,
              graduates, employers, couples, and families.
            </div>
            <div className="flex gap-6 mt-4">
              {/* Email Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href ="mailto:bhoomika-aus@immigetglobal.com";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconleft}
                      alt="icon-left"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* Phone Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "tel:+610487528282";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110  hover:translate-y-1"
                  >
                    <img
                      src={iconright}
                      alt="icon-right"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* LinkedIn Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "https://www.linkedin.com/in/bhoomikagodara/";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconlinkedin}
                      alt="linkedin-icon"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="flex gap-3 sm:justify-center md:justify-start max-md:mt-2 mt-4">
              <div>
                <Link to="https://www.facebook.com/">
                  {" "}
                  <img src={facbook} alt="" />
                </Link>
              </div>
              <div>
                <Link to="https://x.com/?lang=en&mx=2">
                  {" "}
                  <img src={twitter} alt="" />
                </Link>
              </div>
              <div>
                <Link to="https://www.linkedin.com/home/?originalSubdomain=in">
                  {" "}
                  <img src={linkdin} alt="" />
                </Link>
              </div>
              <div>
                <Link to="">
                  {" "}
                  <img src={bee} alt="" className="w-[32px] h-[32px]" />
                </Link>
              </div>
            </div> */}
          </div>
          <div className="lg:w-[40%] hidden lg:block  bg-blue rounded-[15px] pr-2">
            <img
              src={aboutImageright}
              alt=""
              className="mb-2 h-[500px] rounded-[15px]"
            />
          </div>
        </div>
      </div>

      <div className="max-w-[1440px] px-4 lg:px-12 mx-auto lg:py-8 py-2">
        <div className="lg:flex gap-8 justify-between">
          <div className="lg:w-[40%] bg-blue rounded-[15px]">
            <img
              src={isthiaDsc}
              alt="left-content"
              className="ml-4 mt-4 md:h-[520px] rounded-[15px]"
            />
          </div>
          <div className="lg:w-[60%] flex flex-col gap-2 ml-4">
            <div className="text-[#284760] max-md:mt-2 hellix-font text-[32px] lg:text-[48px] font-semibold">
              Dr. Ishita Patel
            </div>
            <div className="text-[#284760] text-xl font-semibold">
              Australian Medical Regulatory Advisor
            </div>
            <div className="lg:text-[22px] text-lg text-[#1e1e1e] hellix-font font-normal">
              Dr. Ishita Patel is currently working in Australia as a  specialist trainee in Radiology. 
              Having faced the challenging journey of an international medical graduate, 
              she was inspired to support aspiring medical professionals who envisions to move to Australia as Doctors, At Immiget Global, Dr. Patel is responsible to guide overseas medical professionals through the regulatory requirements, exam preparation, ultimately, simplifying the migration for them.
            </div>
            <div className="flex gap-6 mt-4">
              {/* Email Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "mailto:contact@immigetglobal.com";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconleft}
                      alt="icon-left"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* Phone Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "tel:+610420340811";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconright}
                      alt="icon-right"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>

              {/* LinkedIn Button */}
              <div className="flex gap-4 sm:justify-center max-md:mt-2 items-center">
                <div>
                  <button
                    onClick={(e) => {
                      window.location.href = "https://www.linkedin.com/in/";
                      e.preventDefault();
                    }}
                    className="flex items-center text-white py-2 px-4 rounded-md  transition-all duration-300 transform hover:scale-110 hover:translate-y-1"
                  >
                    <img
                      src={iconlinkedin}
                      alt="linkedin-icon"
                      className="mr-2 w-[40px] h-[40px] transition-all duration-300 transform hover:scale-125 "
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="flex gap-3 sm:justify-center md:justify-start max-md:mt-2 mt-4">
              <div>
                <Link to="https://www.facebook.com/">
                  {" "}
                  <img src={facbook} alt="facebook-icon" />
                </Link>
              </div>
              <div>
                <Link to="https://x.com/?lang=en&mx=2">
                  {" "}
                  <img src={twitter} alt="twitter-icon" />
                </Link>
              </div>
              <div>
                <Link to="https://www.linkedin.com/home/?originalSubdomain=in">
                  {" "}
                  <img src={linkdin} alt="linkdin-iicon" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      

      <div className="max-w-[1440px] px-4 lg:px-12 mx-auto">
        <div className="flex flex-col md:flex-row p-5 space-y-4 md:space-x-4 shadow-lg mt-8 hover:shadow-xl transition duration-300 bg-white rounded-lg mx-auto">
          <div className="w-full md:w-[25%] max-md:w-[100%] flex-shrink-0">
            <img src={ourVission} alt="Vision" className="max-md:w-[100%]" />
          </div>
          <div className="w-full md:w-[75%]">
            <div className="mb-4 ml-5 max-sm:ml-0">
              <div className="text-xl hellix-font  md:text-[32px] max-sm:text-center font-Hellix font-semibold text-[#004b6b] mb-2">
                Our Vision
              </div>
              <div className="text-lg  hellix-font  md:text-[22px] max-md:text-left font-Hellix font-normal text-[#004b6b] max-w-full md:max-w-[839px]">
                Immiget Global envisions a world where migration is seamless,
                accessible, life-changing & easy. We strive to create a global
                community where people can freely explore opportunities,
                cultivate diversity, and thrive in their space with the choices
                they wish to make. We aim to be a go-to destination for clients
                who aspire to settle in Australia or anywhere else in the world.
                 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] px-4 sm:px-6 md:px-8 mt-4 lg:px-12 mx-auto mb-10">
        <div className="flex flex-col-reverse md:flex-row p-5 mt-5 space-y-4 md:space-x-4 shadow-lg hover:shadow-xl transition duration-300 bg-white rounded-lg mx-auto">
          <div className="w-full md:w-[70%]">
            <div className="">
              <div className="text-xl hellix-font  mt-6 sm:text-2xl max-sm:text-center max-md:mt-4 font-Hellix md:text-[32px] font-semibold text-[#004b6b] mb-2">
                Our Mission
              </div>
              <div className="text-lg hellix-font  font-Hellix max-md:text-left sm:text-[22px] font-normal text-[#004b6b] max-w-full md:max-w-[900px]">
                At Immiget Global, our mission is to deliver expert migration
                solutions with integrity, and professionalism. We are committed
                to: 
                <br />
                Simplifying complex migration processes through smart
                practices and expert guidance. Providing personalized support
                and care to ensure a stress-free experience for our clients.
                Ensuring successful outcomes through meticulous application
                preparation and strategic representation. Developing a community
                of empowered global citizens through précised migration advice,
                awareness, and advocacy. 
              </div>
            </div>
          </div>
          <div className="w-full md:w-[25%] flex-shrink-0 max-md:w-[100%]">
            <img src={ourMission} alt="Mission " className="max-md:w-[100%]" />
          </div>
        </div>
      </div>
      <div className="container max-w-1440px mx-auto px-4 lg:px-14 py-10">
        <div className="flex justify-center items-center pb-10 mt-12">
          <div className="w-2 h-2 bg-blue rotate-45">.</div>
          <div className="w-[790px] h-[2px] bg-blue"></div>
          <div className="w-2 h-2 bg-blue rotate-45">.</div>
        </div>

        <div className="max-w-[1264px] text-center">
          <div className="text-[38px] lg:text-[54px] text-blue hellix-font font-semibold">
            Our Core Values
          </div>
          <div className="text-[22px] text-blue hellix-font font-normal">
            At Immiget Global, our core values serve as the bedrock of our
            migration practice, guiding our approach to delivering exceptional
            services to individuals and organizations navigating the
            complexities of global migration. 
          </div>
        </div>
      </div>
      <div className="lg:flex justify-center  container max-w-1440px  gap-[100px] px-4 mt-8 mb-8">
        <div className="flex flex-col lg:w-[330px] gap-10 lg:gap-20">
          <div
            className="w-[123px] flex justify-center items-center h-[123px] bg-[#ffffff] rounded-[61.5px] relative"
            style={{
              borderLeft: "3px solid #004b6b",
              boxShadow: "0px 3px 13px -10px rgba(0, 0, 0, 0.18)",
              zIndex: 1,
            }}
          >
            <img src={coreTopLeft} alt="core-left" />
            <div
              className="w-[236px] absolute top-[54px] left-[100px]  flex justify-center items-center text-blue text-2xl font-semibold h-[94px]"
              style={{
                boxShadow: "10px 3px 13px 0px rgba(0, 0, 0, 0.18)",
                zIndex: 2,
                marginTop: "-40px",
                borderTopRightRadius: "60px",
                borderBottomRightRadius: "60px",
              }}
            >
              Empathy
            </div>
          </div>
          <div
            className="w-[123px] flex justify-center items-center h-[123px] bg-[#ffffff] rounded-[61.5px] relative"
            style={{
              borderLeft: "3px solid #004b6b",
              boxShadow: "0px 3px 13px -10px rgba(0, 0, 0, 0.18)",
              zIndex: 1,
            }}
          >
            <img src={corebootomLeft} alt="core-left" />
            <div
              className="w-[236px] absolute top-[54px] left-[100px]  flex justify-center items-center text-blue text-2xl font-semibold h-[94px]"
              style={{
                boxShadow: "10px 3px 13px 0px rgba(0, 0, 0, 0.18)",
                zIndex: 2,
                marginTop: "-40px",
                borderTopRightRadius: "60px",
                borderBottomRightRadius: "60px",
              }}
            >
              Integrity
            </div>
          </div>
        </div>
        <div className="lg:w-[250px]">
          <div className="flex justify-center ml-6">
            <img src={middleimage} alt="middle-image" className="h-[350px]" />
          </div>
          <div>
            <div
              className="w-[123px] flex justify-center items-center h-[123px] bg-[#ffffff] rounded-[61.5px] relative"
              style={{
                borderLeft: "3px solid #004b6b",
                boxShadow: "0px 3px 13px -10px rgba(0, 0, 0, 0.18)",
                zIndex: 1,
              }}
            >
              <img src={blubmiddle} alt="core-left" />
              <div
                className="w-[236px] absolute top-[54px] left-[100px]  flex justify-center items-center text-blue text-2xl font-semibold h-[94px]"
                style={{
                  boxShadow: "10px 3px 13px 0px rgba(0, 0, 0, 0.18)",
                  zIndex: 2,
                  marginTop: "-40px",
                  borderTopRightRadius: "60px",
                  borderBottomRightRadius: "60px",
                }}
              >
                Innovation
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:w-[330px] max-lg:mt-4 gap-10 lg:gap-20">
          <div
            className="w-[123px] flex justify-center items-center h-[123px] bg-[#ffffff] rounded-[61.5px] relative"
            style={{
              borderLeft: "3px solid #004b6b",
              boxShadow: "0px 3px 13px -10px rgba(0, 0, 0, 0.18)",
              zIndex: 1,
            }}
          >
            <img src={excellent} alt="core-left" />
            <div
              className="w-[236px] absolute top-[54px] left-[100px]  flex justify-center items-center text-blue text-2xl font-semibold h-[94px]"
              style={{
                boxShadow: "10px 3px 13px 0px rgba(0, 0, 0, 0.18)",
                zIndex: 2,
                marginTop: "-40px",
                borderTopRightRadius: "60px",
                borderBottomRightRadius: "60px",
              }}
            >
              Excellence
            </div>
          </div>
          <div
            className="w-[123px] flex justify-center items-center h-[123px] bg-[#ffffff] rounded-[61.5px] relative"
            style={{
              borderLeft: "3px solid #004b6b",
              boxShadow: "0px 3px 13px -10px rgba(0, 0, 0, 0.18)",
              zIndex: 1,
            }}
          >
            <img src={collobration} alt="core-left" />
            <div
              className="w-[236px] absolute top-[54px] left-[100px]  flex justify-center items-center text-blue text-2xl font-semibold h-[94px]"
              style={{
                boxShadow: "10px 3px 13px 0px rgba(0, 0, 0, 0.18)",
                zIndex: 2,
                marginTop: "-40px",
                borderTopRightRadius: "60px",
                borderBottomRightRadius: "60px",
              }}
            >
              Collaboration
            </div>
          </div>
        </div>
      </div>

      <Contact />
      {/* <Question /> */}
    </div>
  );
};

export default AboutUs;
