// src/config/apiConfig.js
const getApiUrl = () => {
    const isLocalhost = window.location.hostname === 'localhost';
    const apiUrl = isLocalhost 
        ? 'http://localhost:5000' 
        : '/api';  // This will use relative path
        
    console.log('API URL:', apiUrl);
    return apiUrl;
};

export const API_URL = getApiUrl();