import React, { useState } from "react";
import footLogo from "../images/footer/footer-Logo.svg";
import migration from "../images/footer/migration-allience.png";
import facebook from "../images/footer/facebook-logo.svg";
import linkedin from "../images/footer/linkedin-logo.svg";
import instagram from "../images/footer/instagram-logo.svg";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing icons

const Footer = () => {
  const [showEmployers, setShowEmployers] = useState(false);
  const [showIndividual, setIndividual] = useState(false);

  return (
    <div className=" bg-blue">
      <div className="container max-w-1440px mx-auto px-4 py-10 sm:px-8 lg:px-14">
        {/* Top Section */}
        <div className="flex flex-wrap justify-between items-center gap-4">
          <div>
            <Link to="/">
              <img
                className="w-[300px] md:w-full"
                src={footLogo}
                alt="Footer Logo"
              />
            </Link>{" "}
          </div>
          <div className="hidden md:block">
            <div className="hellix-font text-lg sm:text-[22px] font-semibold text-white flex items-center pb-3">
              Member of
            </div>
            <div>
              <img src={migration} alt="Migration Alliance" />
            </div>
          </div>
        </div>

        {/* Middle Section */}
        <div className="flex flex-wrap justify-between pt-4">
          {/* Left Column */}
          <div className="w-full sm:w-1/4 text-white space-y-6 md:px-4 mb-4 md:mb-0">
            <div className="font-bold hellix-font text-xl sm:text-[22px] cursor-pointer">
              MARN: 2418453
            </div>
            <div>
            <Link
              to="/docs/code-of-conduct.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="font-normal hellix-font text-base underline opacity-80">
                MARA Code of Conduct
              </Link>
            </div>
            <div>
            <Link
              to="/docs/consumer_guide_english.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="font-normal hellix-font text-base underline opacity-80">
                Migration Consumer Guide
              </Link>
            </div>

            <p className="font-normal hellix-font text-sm opacity-80">
              We are the most trusted Migration Consultants with footprints in 3
              countries globally: Australia, India & Philippines. Registered
              migration agents with a proven success rate of 98.9%. We remain
              the first preference of International graduates for migration
              assistance.
            </p>
          </div>

          <div className="hidden sm:block">
            {/* For Employers */}
            <div className="w-full    flex  flex-col gap-y-4 mb-4 md:mb-0">
              <h3 className="font-bold hellix-font text-xl sm:text-[22px] text-white">
                For Employers
              </h3>

              <Link
                to="/employee-nominated-schema-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 186
              </Link>
              <Link
                to="/employee-religion-migration"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 187
              </Link>
              <Link
                to="/employee-temporary-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 400
              </Link>
              <Link
                to="/employee-skilled-religion"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 494
              </Link>
              <Link
                to="/employee-training-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 407
              </Link>
              <Link
                to="/employee-temporary-skilled-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 482
              </Link>
            </div>
          </div>
          <div className="sm:hidden">
            <div className="flex flex-col  sm:flex-row gap-4">
              {/* For Employers */}
              <div className="w-full sm:w-1/4 flex flex-col gap-y-4 mb-4 md:mb-0">
                <h3
                  className="font-bold hellix-font text-xl sm:text-[22px] text-white cursor-pointer flex items-center gap-4"
                  onClick={() => setShowEmployers(!showEmployers)} // Toggle dropdown visibility
                >
                  For Employers
                  {/* Displaying the chevron icon based on the state */}
                  {showEmployers ? (
                    <FaChevronUp className="text-white" />
                  ) : (
                    <FaChevronDown className="text-white" />
                  )}
                </h3>

                {/* Dropdown Links */}
                {showEmployers && (
                  <div className="flex flex-col gap-y-2">
                    <Link
                      to="/employee-nominated-schema-visa"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 186
                    </Link>
                    <Link
                      to="/employee-religion-migration"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 187
                    </Link>
                    <Link
                      to="/employee-temporary-visa"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 400
                    </Link>
                    <Link
                      to="/employee-skilled-religion"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 494
                    </Link>
                    <Link
                      to="/employee-training-visa"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 407
                    </Link>
                    <Link
                      to="/employee-temporary-skilled-visa"
                      className="font-medium hellix-font text-base text-white"
                    >
                      Subclass - 482
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            {/* Individual & Families */}
            <div className="w-full  flex flex-col gap-y-4 mb-4 md:mb-0">
              <h3 className="font-bold hellix-font text-xl sm:text-[22px] text-white">
                Individual & Families
              </h3>

              <Link
                to="/skill-independent-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 189
              </Link>
              <Link
                to="/skill-nominated-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 190
              </Link>
              <Link
                to="/skill-work-religion"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 491
              </Link>
              <Link
                to="/visitor-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 600
              </Link>
              <Link
                to="/temporary-Graduate-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 485
              </Link>
              <Link
                to="/partner-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 820/801 & Subclass - 309/100
              </Link>
              <Link
                to="/parent-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 103 & 143
              </Link>
              <Link
                to="/student-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Subclass - 500 & 590
              </Link>
              <Link
                to="/family-visa"
                className="font-medium hellix-font text-base text-white"
              >
                Family Visa
              </Link>
            </div>
          </div>

          <div className="sm:hidden">
            {/* Individual & Families */}
            <div className="w-full sm:w-1/4 flex flex-col gap-y-4 mb-4 md:mb-0">
              <h3
                className="font-bold hellix-font text-xl sm:text-[22px] text-white flex items-center gap-4 cursor-pointer"
                onClick={() => setIndividual(!showIndividual)}
              >
                Individual & Families
                {showIndividual ? (
                  <FaChevronUp className="text-white" />
                ) : (
                  <FaChevronDown className="text-white" />
                )}
              </h3>
              {/* Dropdown Links */}
              {showIndividual && (
                <div className="flex flex-col gap-y-2">
                  <Link
                    to="/skill-independent-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 189
                  </Link>
                  <Link
                    to="/skill-nominated-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 190
                  </Link>
                  <Link
                    to="/skill-work-religion"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 491
                  </Link>
                  <Link
                    to="/visitor-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 600
                  </Link>
                  <Link
                    to="/temporary-Graduate-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 485
                  </Link>
                  <Link
                    to="/partner-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 820/801 & Subclass - 309/100
                  </Link>
                  <Link
                    to="/parent-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 103 & 143
                  </Link>
                  <Link
                    to="/student-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Subclass - 500 & 590
                  </Link>
                  <Link
                    to="/family-visa"
                    className="font-medium hellix-font text-base text-white"
                  >
                    Family Visa
                  </Link>
                </div>
              )}
            </div>
          </div>

          {/* Other Links */}
          <div className="sm:w-1/6 flex flex-col gap-y-4 md:gap-y-6 items-start md:pt-8 mt-4">
            <div className="font-bold hellix-font text-xl sm:text-[22px] text-white ">
              <Link to="/student-assesment">
                Skill Assessment & Registration
              </Link>
            </div>
            <div className="font-bold hellix-font text-xl sm:text-[22px] text-white">
              <Link to="/about">About Us</Link>
            </div>
            <div className="font-bold hellix-font text-xl sm:text-[22px] text-white">
              <Link to="#">Blogs</Link>
            </div>
            <div className="font-bold hellix-font text-xl sm:text-[22px] text-white">
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>

          <div className="block md:hidden">
            <div className="hellix-font text-lg sm:text-[22px] font-semibold text-white flex items-center pb-3">
              Member of
            </div>
            <div>
              <img src={migration} alt="Migration Alliance" />
            </div>
          </div>
        </div>

        {/* Divider and Social Links */}
        <div className="hidden md:flex items-center justify-between mt-10">
          <div className="flex-1 h-[2px] bg-white"></div>
          <div className="flex justify-between gap-4 ml-4">
            <Link
              to="https://www.facebook.com/immigetglobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/immigetglobal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" />
            </Link>
            <Link
              to="https://www.instagram.com/immigetglobal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </Link>
          </div>
          <div className="flex-1 h-[2px] bg-white ml-4">
            <div className="font-normal text-base hellix-font text-white pt-3 text-right">
              Copyright ©{" "}
              <span className="font-semibold text-base hellix-font text-white">
                2024 Immiget Global.
              </span>{" "}
              All Rights Reserved.
            </div>
          </div>
        </div>

        {/* responsive */}
        <div className="grid md:hidden mt-5">
          <hr />
          <div className="flex justify-center gap-10 ml-4 my-4">
            <Link
              to="https://www.facebook.com/immigetglobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/immigetglobal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" />
            </Link>
            <Link
              to="https://www.instagram.com/immigetglobal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </Link>
          </div>

          <hr />

          <div className="w-full  mt-3">
            <div className="font-normal text-base max-md:text-center hellix-font text-white md:text-right">
              Copyright ©{" "}
              <span className="font-semibold text-base hellix-font text-white">
                2024 Immiget Global.
              </span>{" "}
              All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
