import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import applicationIcon from "../../images/individual/ellipticalIcon.svg";
import careerAdvancement from "../../images/employee/carrer-adverstiment.svg";
import labourAgreement from "../../images/individual/LabourAgreement.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import contactSign from "../../images/individual/VisaStreams.jpg";
import occupationalMedicine from "../../images/individual/TSS.jpg";
import immigetGlobal from "../../images/individual/immiget-global.png";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";

const EmployeeTemporarySkilledVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
            Skills In Demand Visa (Subclass 482) 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Skills In Demand Visa (Subclass 482) 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Temporary Skill Shortage (TSS) 
Visa (Subclass 482) */}
      <div className="xl:flex gap-8 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Skills In Demand Visa (Subclass 482) 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
          The Skills in Demand (SID) visa, also known as Subclass 482, has replaced the Temporary Skill Shortage (TSS) visa, 
          offering greater opportunities for skilled workers and employers. This visa is tailored to meet Australia’s workforce needs 
          by addressing skill shortages in various sectors.
          <br />
          <br />
          This visa is a perfect fit for skilled workers looking to contribute to Australia’s economy while gaining a pathway to 
          permanent residency. Whether you’re a highly paid specialist or a skilled worker in a critical industry, 
          the SID visa provides opportunities to work, grow, and eventually settle in Australia.
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={occupationalMedicine} alt="banner-image" />
        </div>
      </div>

      {/* Visa Streams  */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Streams of the Skills in Demand Visa
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The SID visa has three distinct streams, each addressing different needs in Australia’s labour market:
        </div>
        <div className="xl:flex gap-4 justify-center items-center">
          <div className="xl:w-[50%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              Specialist Skills Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Designed for highly skilled professionals earning a minimum annual salary of $135,000 or more.
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Offers expedited processing times of just 7 days.
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  This stream is open to most occupations, excluding trades, machinery operators, drivers, and labourers.
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[50%]">
            <img src={contactSign} alt="Contact Sign" />
          </div>
        </div>

        <div className="xl:flex gap-4 justify-center items-center mt-12">
          <div className="xl:w-[40%]">
            <img src={labourAgreement} alt="Meeting Corparte" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              Labour Agreement Stream:
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                    Focuses on addressing shortages in lower-paid skilled jobs, especially in industries like aged care, disability support, and other essential services.
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                    Applicants must earn below the CST salary threshold and be sponsored under an approved labour agreement.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] pt-3 hellix-font">
          These streams offer flexibility for employers to sponsor skilled
          workers, addressing specific labor market needs. 
        </div>
      </div>
      
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
            Four-Year Visa Validity
            </div>
          </div>
          <div className="shadow-custom-white  p-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={careerAdvancement} alt="Career-Advancement" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Pathway to permanent residence 
            </div>
          </div>

          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={applicationIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Transparency for Sponsors
            </div>
          </div>
        </div>
      </div>

      {/* Application Process   */}
      <div className=" container max-w-1440px  mx-auto px-4  lg:px-14">
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Four-Year Visa Validity:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            All SID (subclass 482) visas are valid for up to four years, providing stability for both workers and employers.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Pathway to Permanent Residency
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Time spent working under this visa counts toward permanent residency requirements via the Employer Nomination Scheme (Subclass 186) visa. Changing employers doesn’t reset the time period, offering more flexibility.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Transparency for Sponsors:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
              A public register of approved sponsors allows workers to easily identify potential employers for sponsorship.
          </div>
        </div>
      </div>

      {/* How Immiget Global Can Assist with Your Subclass 600 Application */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img
              src={immigetGlobal}
              alt="Immiget Global"
              className="h-[100%]"
            />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-4">
            <ul className="list-inside">
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-3">
                <span>&bull;</span>
                <div>
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-2">
                <span>&bull;</span>
                <div>
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Skills in Demand (SID) Visa (Subclass 482) FAQs
        </div>
        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. What is the Skills in Demand (SID) Visa (Subclass 482)?
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The SID Visa, also known as Subclass 482, is a temporary work visa designed to address skill shortages in Australia by allowing skilled workers to work for an approved sponsor. It offers flexibility, a pathway to permanent residency, and caters to various industries and skill levels.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. How long is the SID visa valid?
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The SID visa is valid for up to four years, providing stability for visa holders and their employers.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Can I apply for permanent residency through the SID visa?
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, all SID visa holders, regardless of the stream, have a clear pathway to permanent residency through the Employer Nomination Scheme (Subclass 186). Time spent working under the SID visa counts toward the residency requirements, even if you change employers.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the streams under the SID visa?
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Specialist Skills Stream: For high-income earners ($135,000+ annually) with priority processing.
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Core Skills Stream: For a broader range of occupations, including trades and healthcare roles, with a minimum salary threshold of $73,150.
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Labour Agreement Stream: For lower-paid skilled workers under approved labour agreements, focusing on sectors like aged care and support services.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. What is the Core Skilled Threshold (CST) salary?
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The CST salary is the minimum annual income required for the Core Skills Stream, currently set at $73,150. It is reviewed and indexed annually to reflect economic changes.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. How can I find a sponsor for the SID visa? {" "}
              </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The Australian government provides a public register of approved business sponsors. This resource allows potential visa applicants to identify suitable employers who can sponsor them.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. What is the processing time for the SID visa?
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Specialist Skills Stream: 7 days
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Core Skills Stream: 21 days (median processing time)
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Labour Agreement Stream: Processing times may vary depending on the sponsor’s agreement.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. Can I change employers while on the SID visa? {" "}
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, you can change employers without resetting your time towards permanent residency. However, the new employer must be an approved sponsor under the SID visa program.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. Are family members allowed to accompany me on this visa?? {" "}
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, eligible family members, such as your spouse and dependent children, can be included in your application and join you in Australia.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. What occupations are eligible under the SID visa?? {" "}
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Specialist Skills Stream: Open to most occupations except trades, machinery operators, drivers, and labourers.
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Core Skills Stream: Occupations listed on the Core Skills Occupation List (CSOL).
                </p>
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Labour Agreement Stream: Occupations agreed upon between the employer and the government.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 11*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(11)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                11. What happens if I earn below the Core Skilled Threshold (CST)?
              </h2>
              {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 11 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  If your salary is below the CST, you may still qualify for the Labour Agreement Stream, provided your sponsor has an approved labour agreement with the Australian government.
                </p>
              </div>
            )}
          </div>

           {/* Accordion Section 12*/}
           <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(12)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                12. Can my visa be extended?
              </h2>
              {activeIndex === 12 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 12 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, the SID visa can be extended as long as you meet the eligibility criteria and continue to be sponsored by an approved employer.
                </p>
              </div>
            )}
          </div>

           {/* Accordion Section 13*/}
           <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(13)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                13. How does the SID visa address Australia’s skill shortages?
              </h2>
              {activeIndex === 13 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 13 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The SID visa aligns skilled workers with industries facing shortages, including healthcare, construction, and essential services. It ensures that employers can fill critical roles with qualified individuals from around the world.
                </p>
              </div>
            )}
          </div>

           {/* Accordion Section 14*/}
           <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(14)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                14. Is there a language requirement for the SID visa?
              </h2>
              {activeIndex === 14 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 14 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, applicants typically need to demonstrate English proficiency, which can be proven through tests such as IELTS, PTE, or TOEFL, unless exempted.
                </p>
              </div>
            )}
          </div>

           {/* Accordion Section 15*/}
           <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(15)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                15. What should I do if my visa application is refused?
              </h2>
              {activeIndex === 15 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 15 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  If your SID visa application is refused, you may have the option to appeal the decision through the Administrative Appeals Tribunal (AAT). It’s advisable to seek professional assistance for the appeal process.
                </p>
              </div>
            )}
          </div>
    
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default EmployeeTemporarySkilledVisa;
