import React, { useState, useEffect } from 'react';

const LogoCarousel = ({ logos = [
  // Default logos - replace with your client's logos
  { id: 1, src: "/api/placeholder/200/100", alt: "Client Logo 1" },
  { id: 2, src: "/api/placeholder/200/100", alt: "Client Logo 2" },
  { id: 3, src: "/api/placeholder/200/100", alt: "Client Logo 3" },
  { id: 4, src: "/api/placeholder/200/100", alt: "Client Logo 4" },
  { id: 5, src: "/api/placeholder/200/100", alt: "Client Logo 5" },
  { id: 6, src: "/api/placeholder/200/100", alt: "Client Logo 6" },
  { id: 7, src: "/api/placeholder/200/100", alt: "Client Logo 7" },
  { id: 8, src: "/api/placeholder/200/100", alt: "Client Logo 8" },
] }) => {
  const [position, setPosition] = useState(0);
  const visibleLogos = 5; // Number of logos visible at once

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        if (prevPosition >= logos.length - visibleLogos) {
          return 0;
        }
        return prevPosition + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [logos.length]);

  return (
    <div className="w-full overflow-hidden bg-white py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="relative overflow-hidden">
          <div 
            className="flex transition-transform duration-500 ease-in-out"
            style={{ 
              transform: `translateX(-${(position * (100 / visibleLogos))}%)`,
            }}
          >
            {logos.map((logo) => (
              <div
                key={logo.id}
                className="flex-shrink-0"
                style={{ width: `${100 / visibleLogos}%` }}
              >
                <div className="flex items-center justify-center p-4">
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="max-h-20 w-auto object-contain"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;